import React from 'react';
import { useRef, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import RosterPlayer from '../../components/RosterPlayer/RosterPlayer';

import { Helmet } from 'react-helmet';

import './style.scoped.scss';

import background from '../../assets/backgroundroster.png';
import frozyy from '../../assets/avis/frozyy.png';
import acid from '../../assets/avis/acid.png';
import devon from '../../assets/avis/devon.jpg';
import torro from '../../assets/avis/torro.png';
import miki from '../../assets/avis/miki.png';
import jxm from '../../assets/avis/jxm.png';
import hizo from '../../assets/avis/hizo.png';
import drew from '../../assets/avis/drew.png';
import ant from '../../assets/avis/ant.png';
import aewo from '../../assets/avis/aewo.png';

function Roster() {
    const roles = useRef(null);
    const receiptPolicy = useRef(null);
    const top = useRef(null);
    const [searchParams] = useSearchParams();

    /*const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }*/

    useEffect ( () => {
        switch(searchParams.get("target")) {
            case "roles":
                roles.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "receipt_and_policy":
                receiptPolicy.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            default:
                top.current?.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    });

    return (
        <div className='standards' ref={top}>
            <Helmet>
                <title>Sypher Project - Roster</title>
                <meta content="Sypher Project - Roster" property="og:title" />
                <meta content="Meet the people behind Sypher Project." property="og:description" />
                <meta content="https://sypher-project.com" property="og:url" />
                <meta content="https://cdn.discordapp.com/attachments/1019638590524887082/1231671320006234122/image.png?ex=6626aace&is=6625594e&hm=171d011ef88f5c1c4d92ada385c96429e90546dfa249dc8049c19bc1e197c71b&" property="og:image" />
                <meta content="#ffa201" data-react-helmet="true" name="theme-color" />
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            
            <img id="background" src={background} alt=''/>

            <div className="flex-container">
                <div className="infos">
                    <div className="title">
                        <label>Roster</label>
                    </div>
                    <div className="subtitle">
                        Learn more about your members in our different departments. Fill your application in our discord server now.
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container">
                    <div className="category">
                        <div className="department-title">Leaders</div>
                        <RosterPlayer name="Picsor" positions={["Leader", "Designer", "Motion-Designer"]} flag="fr"/>
                        <RosterPlayer name="Kaio" positions={["Leader", "No-Flipper"]} flag="kaio"/>
                    </div>
                    
                    <div className="category">
                        <div className="department-title">Management</div>
                        <RosterPlayer name="Mayki" positions={["Designer", "Manager", "Lead-Designer"]} flag="fr"/>
                        <RosterPlayer name="syx." positions={["Freestyler", "Designer", "Manager"]} flag="de"/>
                        <RosterPlayer name="IVOXIGEN" positions={["Manager"]} flag="de"/>
                    </div>
   
                    <div className="category">
                        <div className="department-title">Players</div>
                        <RosterPlayer name="PK" positions={["No-Flipper", "Lead-Player"]} flag="usa"/>
                        <RosterPlayer name="David" positions={["No-Flipper"]} flag="idk"/>
                        <RosterPlayer name="Abrams" positions={["No-Flipper"]} flag="idk"/>
                        <RosterPlayer name="Ultra" positions={["No-Flipper"]} flag="england"/>
                        <RosterPlayer name="D0M" positions={["No-Flipper"]} flag="cz"/>
                        <RosterPlayer name="Arsodot" positions={["No-Flipper"]} flag="idk"/>
                        <RosterPlayer name="SeTup" positions={["No-Flipper", "Freestyler"]} flag="fr"/>
                        <RosterPlayer name="Ather" positions={["No-Flipper"]} flag="idk"/>
                        <RosterPlayer name="Ernie" positions={["No-Flipper"]} flag="idk"/>
                        <RosterPlayer name="bou" positions={["No-Flipper"]} flag="idk"/>
                        <RosterPlayer name="Program" positions={["No-Flipper"]} flag="usa"/>
                        <RosterPlayer name="Rade" positions={["No-Flipper"]} flag="idk"/>
                        <RosterPlayer name="Mister.J" positions={["No-Flipper"]} flag="fr"/>
                        <RosterPlayer name="Bachira" positions={["Freestyler"]} flag="de"/>
                    </div>

                    <div className="category">
                        <div className="department-title">Designers</div>
                        <RosterPlayer name="ned44a" positions={["Designer"]} flag="seas"/>
                        <RosterPlayer name="petrixx" positions={["Designer"]} flag="sk"/>
                        <RosterPlayer name="Ethan" positions={["Designer"]} flag="idk"/>
                        <RosterPlayer name="Rddle" positions={["Designer"]} flag="idk"/>
                    </div>

                    <div className="category">
                        <div className="department-title">Video Designer</div>
                        <RosterPlayer name="Nico" positions={["Editor", "Lead-Editor"]} flag="idk"/>
                        <RosterPlayer name="Spatzi" positions={["Editor"]} flag="de"/>
                        <RosterPlayer name="Mapple" positions={["Editor"]} flag="fr"/>
                        <RosterPlayer name="Magical Cloud" positions={["Editor"]} flag="fr"/>
                        <RosterPlayer name="Dom" positions={["Motion Designer"]} flag="de"/>
                        <RosterPlayer name="Blxnkz" positions={["Editor"]} flag="idk"/>
                        <RosterPlayer name="Vini" positions={["Editor"]} flag="idk"/>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Roster;




// { /*<div className="sub-content">
//                         <div className="member">
//                             <img src={frozyy} alt="" className="avi" />
//                             <label htmlFor="" className="name">Frozyy <div className="discord-tooltip">Frozyy#4997</div></label>
//                             <label htmlFor="" className="title">Freestyler</label>
//                             <div className="icon-container">
//                                 <a href="https://www.youtube.com/channel/UCB_sCfql1gebzSSPuB_hpDQ"> <i class="fi fi-brands-youtube"></i> </a>
//                                 <a href="https://twitter.com/Frozyy42"> <i class="fi fi-brands-twitter"></i> </a>
//                                 <a href="https://www.tiktok.com/@frozyy.rl%7D"> <i class="fi fi-brands-tik-tok"></i> </a>
//                             </div>
//                         </div>

//                         <div className="member">
//                             <img src={aewo} alt="" className="avi" />
//                             <label htmlFor="" className="name">Aewo <div className="discord-tooltip">曖濼 𓂃˖♡#0071</div></label>
//                             <label htmlFor="" className="title">Freestyler</label>
//                             <div className="icon-container">
//                                 <a href="https://www.youtube.com/channel/UCB_sCfql1gebzSSPuB_hpDQ"> <i class="fi fi-brands-youtube"></i> </a>
//                                 <a href="https://twitter.com/Frozyy42"> <i class="fi fi-brands-twitter"></i> </a>
//                                 <a href="https://www.tiktok.com/@frozyy.rl%7D"> <i class="fi fi-brands-tik-tok"></i> </a>
//                             </div>
//                         </div>

//                         <div className="member">
//                             <img src={drew} alt="" className="avi" />
//                             <label htmlFor="" className="name">Drew <div className="discord-tooltip">Đrew#3052</div></label>
//                             <label htmlFor="" className="title">Freestyler</label>
//                             <div className="icon-container">
//                                 <a href="https://www.youtube.com/channel/UCB_sCfql1gebzSSPuB_hpDQ"> <i class="fi fi-brands-youtube"></i> </a>
//                                 <a href="https://twitter.com/Frozyy42"> <i class="fi fi-brands-twitter"></i> </a>
//                                 <a href="https://www.tiktok.com/@frozyy.rl%7D"> <i class="fi fi-brands-tik-tok"></i> </a>
//                             </div>
//                         </div>

//                         <div className="member">
//                             <img src={jxm} alt="" className="avi" />
//                             <label htmlFor="" className="name">JXM <div className="discord-tooltip">Sypher | JXM#4483</div></label>
//                             <label htmlFor="" className="title">Comp-Clip-Hitter</label>
//                             <div className="icon-container">
//                                 <a href="https://www.youtube.com/channel/UCB_sCfql1gebzSSPuB_hpDQ"> <i class="fi fi-brands-youtube"></i> </a>
//                                 <a href="https://twitter.com/Frozyy42"> <i class="fi fi-brands-twitter"></i> </a>
//                                 <a href="https://www.tiktok.com/@frozyy.rl%7D"> <i class="fi fi-brands-tik-tok"></i> </a>
//                             </div>
//                         </div>

//                         <div className="member">
//                             <img src={acid} alt="" className="avi" />
//                             <label htmlFor="" className="name">acid <div className="discord-tooltip">acid†#0360</div></label>
//                             <label htmlFor="" className="title">Manager</label>
//                             <div className="icon-container">
//                                 <a href="https://www.youtube.com/channel/UCB_sCfql1gebzSSPuB_hpDQ"> <i class="fi fi-brands-youtube"></i> </a>
//                                 <a href="https://twitter.com/Frozyy42"> <i class="fi fi-brands-twitter"></i> </a>
//                                 <a href="https://www.tiktok.com/@frozyy.rl%7D"> <i class="fi fi-brands-tik-tok"></i> </a>
//                             </div>
//                         </div>

//                         <div className="member">
//                             <img src={hizo} alt="" className="avi" />
//                             <label htmlFor="" className="name">hiz0 <div className="discord-tooltip">hiz0.rl#2916</div></label>
//                             <label htmlFor="" className="title">Freestyler</label>
//                             <div className="icon-container">
//                                 <a href="https://www.youtube.com/channel/UCB_sCfql1gebzSSPuB_hpDQ"> <i class="fi fi-brands-youtube"></i> </a>
//                                 <a href="https://twitter.com/Frozyy42"> <i class="fi fi-brands-twitter"></i> </a>
//                                 <a href="https://www.tiktok.com/@frozyy.rl%7D"> <i class="fi fi-brands-tik-tok"></i> </a>
//                             </div>
//                         </div>
//                     </div> 
//                 */}