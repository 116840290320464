import React from 'react';
import { useRef, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import './style.scoped.scss';

import background from '../../assets/handbookbg.png';

function TransitionHandbook() {
    const top = useRef(null);

    const welcome_to_a_new_era = useRef(null);
    const table_of_content = useRef(null);
    const mission_statement = useRef(null);
    const overview = useRef(null);
    const new_recruitment_and_standards = useRef(null);
    const stricter_rules_on_inactivity = useRef(null);
    const services = useRef(null);
    const community_and_friendship = useRef(null);
    const feedback_and_suggestions = useRef(null);
    const nda_policy = useRef(null);
    const conclusion = useRef(null);
    
    const [searchParams] = useSearchParams();

    /*const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }*/

    useEffect ( () => {
        switch(searchParams.get("target")) {
            case "welcome_to_a_new_era":
                welcome_to_a_new_era.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "table_of_content":
                table_of_content.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "mission_statement":
                mission_statement.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "overview":
                overview.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "new_recruitment_and_standards":
                new_recruitment_and_standards.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "stricter_rules_on_inactivity":
                stricter_rules_on_inactivity.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "services":
                services.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "community_and_friendship":
                community_and_friendship.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "feedback_and_suggestions":
                feedback_and_suggestions.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "nda_policy":
                nda_policy.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "conclusion":
                conclusion.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            default:
                top.current?.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    });

    return (
        <div className='about' ref={top}>
            
            <img id="background" src={background} alt=''/>

            <div className="flex-container">
                <div className="infos">
                    <div className="title">
                        <label>Transitional Handbook</label>
                    </div>
                    <div className="subtitle">
                        Policies and Procedures for our Restructuration.
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="paragraph">
                    <label className="title" ref={welcome_to_a_new_era}>Welcome to a New Era</label>
                    <p className='text'>Welcome to Sypher, a Rocket League team and coaching platform that is in the process of transitioning into a web agency. This handbook outlines our policies and procedures, as well as the changes that we are implementing during this exciting transition.<br/>
                    <br/>
                    <br/>
                    <br/>
                    <b>CONFIDENTIALITY POLICY AND PLEDGE</b><br/><br/>
                    Any information that a member learns about Sypher, or it’s members, or partners, as a result of managing Sypher that is not otherwise publicly available, constitutes confidential information. Members may not disclose confidential information to anyone who is not another member, staff or management member by Sypher. The disclosure, distribution, or copying of Sypher’s confidential information is strictly prohibited.  Any Member who uses his or her status to disclose any confidential Sypher information will be subject to disciplinary action, which includes demotion and a possible ban from Sypher, depending on the severity of the infringement.  
                    <br/>
                    <br/>
                    It is pivotal that you understand the above policy and pledge to not disclose confidential information.  


                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={table_of_content}>Table Of Content</label>
                    <p className='text'> You can click the following links to navigate our handbook.</p>
                    <ul className="list">
                        <li>• <Link to="/transition?target=welcome_to_a_new_era">Welcome to a New Era</Link></li>
                        <li>• <Link to="/transition?target=table_of_content">Table of Content</Link></li>
                        <li></li>
                        <li>• <Link to="/transition?target=mission_statement">Mission Statement</Link></li>
                        <li>• <Link to="/transition?target=overview">Overview</Link></li>
                        <li>• <Link to="/transition?target=new_recruitment_and_standards">New Recruitment and Standards</Link></li>
                        <li>• <Link to="/transition?target=stricter_rules_on_inactivity">Stricter Rules on Inactivity</Link></li>
                        <li>• <Link to="/transition?target=services">Services</Link></li>
                        <li>• <Link to="/transition?target=community_and_friendship">Community and Friendship</Link></li>
                        <li>• <Link to="/transition?target=feedback_and_suggestions">Feedback and Suggestions</Link></li>
                        <li>• <Link to="/transition?target=nda_policy">NDA Policy</Link></li>
                        <li>• <Link to="/transition?target=conclusion">Conclusion</Link></li>
                    </ul>
                </div>

                <div className="paragraph">
                    <label className="title" ref={mission_statement}>I. Mission Statement</label>
                    <p className='text'>As Sypher transitions into a web agency, we want to provide a brief explanation of what a web agency is and what services we will be offering our clients. A web agency is a company that specializes in creating and maintaining websites, online marketing, branding, and e-commerce solutions for businesses and organizations. Our focus will be on helping our clients establish a strong online presence and achieve their goals in the digital realm.
                    <br/>
                    At Sypher, our mission is to provide exceptional service to our clients and establish ourselves as a respected and reputable web agency. We aim to achieve this by maintaining a high level of quality across all aspects of our business, including our members, services, and communication. We will strive to provide our clients with the best possible experience and ensure that they are satisfied with our services. We will also work to establish a strong and positive reputation for our group and our members.
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={overview}>II. Overview</label>
                    <p className='text'>The Sypher Web Agency Handbook serves as a guide to the policies and expectations for all members of the Sypher team. The handbook outlines the mission statement, recruitment and standards, stricter rules on inactivity, transitioning from a high standard Rocket League team, services offered by the agency, the importance of community and friendship within the team, and the value placed on feedback and suggestions from members.
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={new_recruitment_and_standards}>III. New Recruitment and Standards</label>
                    <p className='text'>We prioritize quality over quantity. Therefore, we will be enforcing stricter standards for recruitment. We will be looking for individuals who are up to our standards and who are able to contribute to our overall goals as a web agency.
                    <br/>
                    <br/>
                    In addition, we will be placing a greater emphasis on the overall fit of a member within our team, including their application quality, communication skills, and ability to work collaboratively.</p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={stricter_rules_on_inactivity}>IV. Stricter Rules on Inactivity</label>
                    <p className='text'>To ensure that our team remains active and productive, we will be enforcing stricter rules on inactivity, regardless of public opinion. Members who fail to meet our standards for activity may be subject to removal from the team.
                    <br/>
                    <br/>
                    Previously, we have accepted members based solely on their skills, sometimes overlooking aspects such as their application quality. However, as we transition into a web agency, it is important for us to maintain a high level of quality across all aspects of our business. This means that we will be shifting our focus from being solely a High Standard Rocket League team to becoming a web agency that offers services such as identity design and more.
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={services}>V. Services</label>
                    <p className='text'>We will offer a variety of services such as identity design, website development, social media management, and more. Our goal is to provide exceptional service to our clients and establish ourselves as a respected and reputable web agency.
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={community_and_friendship}>VI. Community and Friendship</label>
                    <p className='text'>While our primary focus is on providing exceptional service to our clients, we also value the sense of community and friendship within our team. We believe that fostering a positive and supportive environment is crucial to our success as a web agency.
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={feedback_and_suggestions}>VII. Feedback and Suggestions</label>
                    <p className='text'>We value feedback and suggestions from our members. If you have any concerns or ideas for how we can improve, please do not hesitate to reach out to a member of our leadership team.

                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={nda_policy}>VIII. NDA Policy</label>
                    <p className='text'>As a member of Sypher, you may have access to confidential information regarding our operations, projects, or other sensitive details that are not intended to be shared publicly. While we encourage members to contribute to our community, we cannot oblige them to do so.
                    <br/>
                    <br/>
                    We ask that all members of Sypher agree to the following Confidentiality Agreement:
                    <ul className='list'>
                        <li>1. Any confidential information obtained during your membership with Sypher must not be shared with others without prior written consent from the organization. </li><br/>
                        <li>2. Confidential information includes, but is not limited to, information that has not been officially announced or made public by Sypher.</li><br/>
                        <li>3. If you leave Sypher or your membership is terminated for any reason, you must return or delete any confidential information obtained during your time as a member.</li><br/>
                        <li>4. This agreement will remain in effect indefinitely, even after your membership with Sypher has ended.</li><br/>
                    </ul>
                    <br/>
                    <br/>
                    By agreeing to this policy, you are acknowledging the importance of maintaining the confidentiality of Sypher's operations and protecting our organization from any potential harm caused by unauthorized disclosure.
                    </p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={conclusion}>IX. Conclusion</label>
                    <p className='text'>By following the policies and expectations outlined in this handbook, we can work together to achieve our mission of providing exceptional service to our clients and establishing ourselves as a respected and reputable web agency. Thank you for being a part of the Sypher team.
                    </p>
                </div>
            </div>
        </div>
    )
}



export default TransitionHandbook;