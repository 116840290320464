import React from 'react';
import { Link } from 'react-router-dom';
import './style.scoped.scss';
import jp from '../../assets/flags/jp.svg';
import england from '../../assets/flags/england.svg';
import de from '../../assets/flags/de.svg';
import fr from '../../assets/flags/fr.svg';
import kaio from '../../assets/flags/kaio.svg';
import seas from '../../assets/flags/seas.svg';
import canada from '../../assets/flags/canada.svg';
import unknown from '../../assets/flags/unknown.png';
import usa from '../../assets/flags/usa.svg';
import cz from '../../assets/flags/cz.svg';
import sk from '../../assets/flags/sk.svg';

function RosterPlayer(props) {

    return (
        <div className="roster-player">
            <img src={loadFlag(props.flag)} alt=""/>
            <div className="name">{props.name}</div>
            <div className="position-container">
                {
                    props.positions.map((position, index) => {
                        return <div key={index} className={`position ${position.toLowerCase()}`}>{position}</div>
                    })
                }
            </div>
            {/* <div className={`position ${props.position.toLowerCase()}`} >{props.position}</div> */}
        </div>
    )
}

function loadFlag(flag) {
    if (flag === "england") return england
    if (flag === "de") return de
    if (flag === "jp") return jp
    if (flag === "fr") return fr
    if (flag === "kaio") return kaio
    if (flag === "seas") return seas
    if (flag === "canada") return canada
    if (flag === "usa") return usa
    if (flag === "cz") return cz
    if (flag === "sk") return sk
    return unknown
}

export default RosterPlayer;