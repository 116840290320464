import styled from 'styled-components';
import strip from '../../assets/strip.svg';

export default function StripeWave() {
    return (
        <div>
            <Wave />
        </div>
    );
}


const Wave = styled.div`
    position: absolute;
    background-position: center top;
    background-repeat: no-repeat;
    top: 380px;
    background-image: url(${strip});
    height: 600px;
    background-size: 1440px;
    width: 100%;
    z-index: 2;
`;
