import React from 'react';
import { Link } from 'react-router-dom';

import {ReactComponent as Burger} from '../../assets/burger.svg';

import { useTransition, animated } from 'react-spring';
import './style.scoped.scss';

import Modal from '../LoginModal/LoginModal';
import { useState } from 'react';


import HomeIcon from '@mui/icons-material/Home';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import InfoIcon from '@mui/icons-material/Info';
import { isSafari } from 'react-device-detect';

import logo from '../../assets/logo.png';


//import { Button } from '@mui/material';

function Navigation() {
    const [openModal, setOpenModal] = useState(false);
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    const transitions = useTransition(isNavExpanded, {
        from: {
            opacity: 0,
            width: "100%",
            height: "100%",
        },
        enter: {opacity: 1
        },
        leave: {opacity: 0,
        },
    })

    const blurTransitions = useTransition(isNavExpanded, {
        from: {
          zIndex: 12,
          WebkitBackdropFilter: 'blur(0px)',
          backdropFilter: 'blur(0px)',
          opacity: 1,
          top: 0,
          position: "fixed",
          width: "fit-content",
          borderRadius: "20px",
        },
        enter: {WebkitBackdropFilter: 'blur(40px)',
        backdropFilter: 'blur(40px)',
        opacity: 1,
        top: 20,
        },
        leave: {WebkitBackdropFilter: 'blur(0px)',
        backdropFilter: 'blur(0px)',
        opacity: 1,
        top: 0,
        },
    })

    return (
        <div className='navigation'>
            <header>
                <nav>
                    <Link to="/" className="branding">
                        <img src={logo} alt='sypher-logo-2022'/>
                        <label style={{color: "white"}}>Sypher</label>
                    </Link>
                    
                    <ul className="navigation">
                        <li><Link to="/" className="link setCenter" > <HomeIcon/> Home</Link></li>
                        <li><Link to="/standards" className="link setCenter"> <AnalyticsIcon/> Standards</Link></li>
                        <li><Link to="/application" className="link setCenter" > <FormatAlignCenterIcon/> Applications</Link></li>
                        <li><Link to="/about" className="link setCenter"> <InfoIcon/> About</Link></li>
                        <li><a href='https://discord.gg/Jx8EEaUcFm'><div /*onClick={() => setOpenModal(true)}*/ className="link setCenter contact-button">Join Us</div></a></li>
                    </ul>


                    <div className="sub-container">
                        <div onClick={() => setIsNavExpanded(!isNavExpanded)} className={ isNavExpanded ? 'burger-container open' : 'burger-container closed'}>
                            <Burger className='burger' />
                        </div>
                    </div>

                    { /*<ul className={
                            isNavExpanded ? "dropdown-nav expanded" : "dropdown-nav"
                        }>
                        <li><Link to="/" className="link">Home</Link></li>
                        <li><Link to="/about" className="link">About</Link></li>
                        <li><div onClick={() => setOpenModal(true)} className="link contact-button">Join Us</div></li>
                    </ul>*/}

                    { 
                        blurTransitions(
                            (styles, item) => item && <animated.div style={styles}>

                                {
                                    transitions(
                                        (styles, item) => item && <animated.div style={styles}>
                                            <div className={ isSafari ? "dropdown-nav safari-fix" : "dropdown-nav"}>
                                                <div className='item'>
                                                    <Link to="/" className="link" onClick={() => {setIsNavExpanded(!isNavExpanded)}}><HomeIcon/>Home</Link>
                                                </div>
                                                <div className='separator'></div>
                                                <div className='item'>
                                                    <Link to="/standards" className="link" onClick={() => {setIsNavExpanded(!isNavExpanded)}}> <AnalyticsIcon/> Standards</Link>
                                                </div>
                                                <div className='separator'></div>
                                                <div className='item'>
                                                    <Link to="/about" className="link" onClick={() => {setIsNavExpanded(!isNavExpanded)}}> <InfoIcon/>About</Link>
                                                </div>
                                                <div className='separator'></div>

                                                <div><a href="https://discord.gg/SCvaqxHEUS"><div /*onClick={() => setOpenModal(true)}*/ className="link contact-button">Join Us</div></a></div>
                                            </div>
                                        </animated.div>
                                    )
                                }
                            </animated.div>
                        )

                    }

                    {   
                        /*blurTransitions(
                            (styles, item) => item && <animated.div style={styles}>
                                <div className=""></div>
                            </animated.div>
                        )*/
                    }
                </nav>
            </header>

            <Modal trigger={openModal} setTrigger={setOpenModal}/>
        </div>
    );
}

export default Navigation;