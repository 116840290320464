import './App.scss';

import {   Routes,
            Route,
            Outlet,
            useLocation } from 'react-router-dom';

import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';

import Landing from './pages/Landing/Landing';
import Standards from './pages/Standards/Standards';
import Application from './pages/Application/Application';
import About from './pages/About/About';
import Handbook from './pages/Handbook/Handbook';
import Form from './pages/Form/Form';

import { motion } from "framer-motion";
import Roster from './pages/Roster/Roster';
import TransitionHandbook from './pages/TransitionHandbook/TransitionHandbook';





function App() {

  
const PageLayout = ({ children }) => children;

const pageVariants = {
  initial: {
    opacity: 0,
    filter: 'blur(10px)',
    top: -500,
  },
  in: {
    opacity: 1,
    filter: 'blur(0px)',
    top: 0,
  },
  out: {
    opacity: 0,
    filter: 'blur(10px)',
    top: -500,
  }
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: .3
};

const AnimationLayout = () => {
  const { pathname } = useLocation();
  return (
    <PageLayout>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
    </PageLayout>
  );
};

  return (
    <div className="App">
      <Navigation />

      <Routes>
        <Route element={<AnimationLayout />}>
          <Route path='/' element={<Landing />} />
          <Route path='/roster' element={<Roster />} />
          <Route path='/application' element={<Application />} />
          <Route path='/standards' element={<Standards />} />
          <Route path='/about' element={<About />} />
          <Route path='/transition' element={<TransitionHandbook />} />
          <Route path='/handbook' element={<Handbook />} />
          <Route path='/form' element={<Form />} />
        </Route>
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
