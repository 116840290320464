import styled, { keyframes } from 'styled-components';

export default function ProgramBlob() {
    return (
        <div>
            <Blob />
            <Blob2 />
        </div>
    );
}

const move = (x1, y1, x2, y2) => keyframes`
    from {
        transform: translate(${ x1 }, ${ y1 }) rotate(-90deg);
        border-radius: 24% 76% 35% 65% / 27% 36% 63% 73%;
    }

    to {
        transform: translate(${ x2 }, ${ y2 }) rotate(-10deg);
        border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
    }
`;

const move2 = (x1, y1, x2, y2) => keyframes`
    from {
        transform: translate(${ x1 }, ${ y1 }) rotate(-90deg);
        border-radius: 51% 49% 58% 42% / 34% 78% 22% 66%;

    }

    to {
        transform: translate(${ x2 }, ${ y2 }) rotate(-120deg);
        border-radius: 22% 78% 73% 27% / 68% 31% 69% 32%;

    }
`;

const Blob = styled.div`
    position: absolute;
    width: 500px;
    height: 500px;
    z-index: 5;
    background: linear-gradient(180deg, rgba(186, 117, 255, 0.49) 26.56%, rgb(57, 19, 184) 100%);

    mix-blend-mode: color-dodge;
    transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
    animation: ${move('400px', '-50px', '900px', '100px')} 25s infinite alternate;

    :hover {
        width: 520px;
        height: 520px;
        filter: blur(30px);
        box-shadow: rgb(255 255 255 / 60%) 0px 0px 0px 0.5px inset, rgb(250 112 154) 100px 100px 0px 0px inset, rgb(120 75 160) 200px 200px 0px 0px inset, rgb(43 134 197) 300px 300px 0px 0px inset;
    }

    @media (max-width: 700px) {
        width: 300px;
        height: 300px;
        animation: ${move('0', '-50px', '200px', '50px')} 25s infinite alternate;

        :hover {
            width: 320px;
            height: 320px;
        }
    }
`;

const Blob2 = styled(Blob)`
    background: linear-gradient(180deg, rgba(47, 184, 255, 0.42) 31.77%, rgb(158, 236, 217) 100%);

    animation: ${move2('200px', '-50px', '700px', '100px')} 30s infinite alternate;

    @media (max-width: 700px) {
        animation: ${move2('-200px', '50px', '200px', '100px')} 30s infinite alternate;
    }
`;
