import React from 'react';
import { Button } from '@mui/material';
import './style.scoped.scss';

//import Navigation from '../../components/Navigation/Navigation';

import { useRef, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import background from '../../assets/top-background.png';
import octane from '../../assets/octane.png';

import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import ApiIcon from '@mui/icons-material/Api';
import HandshakeIcon from '@mui/icons-material/Handshake';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import illustration1 from '../../assets/illustration1.png';
import illustration2 from '../../assets/illustration2.png';
import illustration3 from '../../assets/illustration3.png';

import carillustration1 from '../../assets/car-illustration1.png';
import carillustration2 from '../../assets/car_illustration_decal2.png';
// import carillustration2 from '../../assets/car-illustration2.png';
import track_icon from '../../assets/track_icon.png';
import discordDev from '../../assets/discord-dev.png';
import mediaIllustration from '../../assets/media-illustration-purple2.png';

import BlobAnimation from '../../components/BlobAnimation/BlobAnimation';
import ProgramBlob from '../../components/BlobAnimation/ProgramBlob';

import BackgroundWaves from '../../components/BackgroundWaves/BackgroundWaves';
import ProgramWave from '../../components/BackgroundWaves/ProgramWave';

import StripWave from '../../components/BackgroundWaves/StripWave';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';



function Landing() {
    const team_recruitment = useRef(null);
    const skills = useRef(null);
    const tests = useRef(null);
    const divisions = useRef(null);
    const predictiveAnalytics = useRef(null);
    const top = useRef(null);
    const [searchParams] = useSearchParams();

    /*const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }*/
    console.log("%c Hey there! Stop snooping around! Instead, why don't you join us?\n https://discord.gg/2Z8Z9Z9", 'color: #ffff; font-size: 20px; font-weight: bold; background-color: rgb(25, 25, 40); border-radius: 10px; padding: 10px; margin-top: 10px;');
    
    useEffect ( () => {
        
        

        switch(searchParams.get("target")) {
            case "predictiveAnalytics":
                predictiveAnalytics.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "divisions":
                divisions.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "tests":
                tests.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "skills":
                skills.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "team_recruitment":
                team_recruitment.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            

            default:
                //top.current?.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    });

    return (
        <div className="global">
            <Helmet>
                <title>Sypher Project</title>
                <meta content="Sypher Project - The Rocket League team you've Asked for" property="og:title" />
                <meta content="Not so professional Rocket League team since 02.20.20. In about 10 minutes, we'll have you in. Stop wasting time. Click the link, join our discord, make an app, get declined... unless?" property="og:description" />
                <meta content="https://sypher-project.com" property="og:url" />
                <meta content="https://cdn.discordapp.com/attachments/1019638590524887082/1231671320006234122/image.png?ex=6626aace&is=6625594e&hm=171d011ef88f5c1c4d92ada385c96429e90546dfa249dc8049c19bc1e197c71b&" property="og:image" />
                <meta content="#ffa201" data-react-helmet="true" name="theme-color" />
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
            
            {//<Navigation/>
            }
            <div className='divider' ref={top}>
                

                <img id="background" src={background} alt=''/>

                <div className="flex-container">
                    <div className="infos">
                        <div className="title">We're the<br/>team you've<br/>
                            <label>Asked for.</label>
                        </div>
                        <div className="subtitle">
                        Gotta catch style, not teams. If our site hasn't won you over, scroll on. Ready to level up? Hit that button and let's roll. Who needs 'em all when you've got us?
                        </div>
                        <br/> <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/Jx8EEaUcFm" style={{
                            textDecoration: 'none',
                            marginTop: '10px',
                        }}> <Button className="button" variant="contained" sx={{ 
                            background: 'linear-gradient(to top, #dbe1ff, #fefeff)',
                            color: '#000',
                            borderRadius: '20px',
                            fontSize: '1.3rem',
                            padding: '0.8rem 1.5rem',
                            fontWeight: 'normal',
                            textTransform: 'none',
                            
                        }}>Join our Discord <OpenInNewIcon sx={{
                            marginLeft: '0.5rem',
                            marginTop: '1px'
                        }} /> </Button></a>
                    </div>
                    <img src={carillustration2} alt=""/>
                </div>

                


                <div className='cat'>
                    
                    <BlobAnimation style={{marginTop: "100px"}} />
                    <BackgroundWaves />

                    <div className="element" ref={predictiveAnalytics}>
                        <div className="text-container">
                            <label htmlFor="">Peak Standards Only</label>
                            <p>Picture this: you're the world's most incompetent applicant. After somehow managing to convince
                                yourself Sypher was to your reach, you decide to apply. You were really hyped but you haven't quite met
                                our requirements. Well, that's sad.</p>
                        </div>
                        <img id='illu1' src={illustration1} alt=""/>
                    </div>


                    <div className="element" ref={divisions}>
                        <img id='illu2' src={illustration2} alt=""/>
                        <div className="text-container">
                            <label htmlFor="">Definitely Organized</label>
                            <p>We're so organized we answer applications. Yup. Your 5 minutes unfinished draft: we'll actually answer.
                                Not that we really care about your application, but we do answer, sometimes. Also we finish our projects, unlike you. 
                            </p>
                            </div>            
                    </div>

                    <div className="element" ref={tests}>
                        <div className="text-container">
                            <label htmlFor="">We see you. Yes.</label>
                            <p>We do. We see you. Actually, we don't, but we will whenever you'll be asking for something. We'll listen, not too much though. Your ideas, concepts, thoughts and complaints will all be taken care of - eventually.</p>

                            </div>
                        <img id='illu3' src={illustration3} alt=""/>
                    </div>
                </div>





                <div className="custom-shape-divider-bottom-1656579604" style={{zIndex: "2", marginBottom: "-2px"}}>
                    <svg style={{zIndex: "2"}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                    </svg>
                </div>
                
                <div className="area" style={{zIndex: "0"}}>
                    <ul className="circles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                    </ul>
                </div >
                
                <div className="split" style={{zIndex: "2"}}></div>

                <div className="global" ref={skills}>
                    <ProgramBlob/>
                    <div className="top section">
                        <div className="texts">
                            <label id='subtitle' htmlFor="">20+ features that you'll love</label>
                            <label htmlFor="" id="title">Benefit of the best tools <br/>and designs</label>
                            <p style={{marginBottom: "30px"}}>Our big brain people said all those things were create. Surely one of them will catch your sight.</p>
                        </div>
                    </div>

                    <div className="media-scroller">            
                        <div className="rect">
                            <div className="rect-txts">
                                <label htmlFor="" className="feat-skills" style={{height: '100px'}}>Featured Solutions</label>
                                <div className="vertical-scroller">
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">1</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Logo Design</label>
                                                <label htmlFor="" className="rating">5 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Create a unique, eye-catching logo that represents your project's identity, values, and competitive spirit.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">2</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Brand Guidelines</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Establish a consistent visual language for your project, including color palette, typography, and design elements for various applications.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">3</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Social Media Graphics</label>
                                                <label htmlFor="" className="rating">4 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Design engaging, visually appealing graphics for your team's social media platforms, including profile pictures (AVIs), banners, and post templates.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">4</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Jersey and Merchandise</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Develop stylish and memorable jersey designs, along with other merchandise items that showcase your team's brand and help you stand out from the competition.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">5</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Team Banners</label>
                                                <label htmlFor="" className="rating">3 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Design visually striking banners for use at events, tournaments, or as online headers, showcasing your team's logo, colors, and sponsors.</label>
                                        </div>
                                    </div>

                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">6</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Custom AVI Sets</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Create personalized and cohesive avatar sets for your team members to use across various platforms, promoting a strong team identity.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">7</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Promotional Material Design</label>
                                                <label htmlFor="" className="rating">3 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Design attention-grabbing posters, flyers, and other promotional materials for your team's events, announcements, or campaigns.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">8</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Stream Overlays and Assets</label>
                                                <label htmlFor="" className="rating">7 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Design custom streaming overlays, panels, and assets to enhance your team's live broadcasts and maintain a consistent brand presence.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">9</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Animated Logo Intros</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Produce captivating animated logo intros for your team's video content, creating a professional and memorable opening for viewers.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">10</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Event Branding</label>
                                                <label htmlFor="" className="rating">7 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Design event-specific branding materials, such as stage backdrops, signage, and digital assets, to create a cohesive and immersive experience for attendees.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">11</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>In Game Decals</label>
                                                <label htmlFor="" className="rating">6 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Let anyone wear your colors and show their support for your team with custom-designed decals that can be applied to in-game objects and surfaces with BakkesMod & AlphaConsole Plugin.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-rect red-gradient">
                                <img className='ilustration-img' id="illuImgFS" src={carillustration1} alt="" />
                                <div className="sub-rect-text-content">
                                    <label htmlFor="" className="field-title" id="fieldtitle1" >Visual Identity</label>
                                    <label htmlFor="" className="field-infos">15 Skills - <span className="material-symbols-outlined">hourglass_bottom</span> Coaches</label>
                                    
                                    <q-btn></q-btn>
                                </div>
                            </div>
                        </div>
                        <div className="rect">
                            <div className="rect-txts">
                                <label htmlFor="" className="feat-skills" style={{height: "90px"}}>Featured Solutions</label>
                                <div className="vertical-scroller">
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">1</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Custom Web Applications</label>
                                                <label htmlFor="" className="rating">5 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Develop tailored web applications to enhance your team's online presence, from custom widgets to interactive features.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">2</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Interactive Forms and Surveys</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Design and implement user-friendly forms and surveys to gather valuable feedback, recruit new members, or manage event registrations.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">3</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Discord to Web Integration</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Integrate your Discord bot with your website, allowing seamless data sharing, communication, and functionality between the two platforms.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">4</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Activity Tracking & Reporting</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Implement tools to monitor and report on your team members' activity across various platforms, ensuring engagement and accountability.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">5</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Content Management Systems Integration</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Deploy a CMS tailored to your team's needs, allowing for easy content updates and management of your online presence.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">6</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Automated Alerts & Notifications</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Set up automated notifications and alerts to keep your team informed about important updates, events, and announcements.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">7</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>API Integration with Third-Party Services</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Connect your website or platform with third-party services, allowing for seamless data exchange and added functionality.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">8</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Secure Member Login and Authentication</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Implement a secure login system for your team members to access restricted content, tools, and resources.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">9</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Real-time Team and Player Stats Display</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Display real-time team and player statistics on your website or platform, keeping fans and members up-to-date on performance.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">10</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Online Collaboration and File Sharing Tools</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Provide your team with easy-to-use online tools for document collaboration and file sharing, streamlining communication and organization, all of it designed with love.</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="sub-rect yellow-gradient">
                                <img className='ilustration-img' id="illuImgComp" src={track_icon} alt=""/>
                                <div className="sub-rect-text-content">
                                    <label htmlFor="" className="field-title">Online Visibility</label>
                                    <label htmlFor="" className="field-infos">15 Skills - <span className="material-symbols-outlined">hourglass_bottom</span> Coaches</label>
                                
                                    <q-btn></q-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <ProgramWave/>

                    <div className="media-scroller" style={{marginTop: "20px"}}>            
                        <div className="rect">
                            <div className="rect-txts">
                        
                                <label htmlFor="" className="feat-skills" style={{height: "90px"}}>Featured Tools</label>
                                <div className="vertical-scroller">
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">1</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Custom Discord Bots</label>
                                                <label htmlFor="" className="rating">0 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Develop custom Discord bots with the latest of Discord's API to automate tasks, manage communication, and provide useful features for your team.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">2</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Scheduling and Calendar Systems</label>
                                                <label htmlFor="" className="rating">0 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Implement scheduling and calendar systems to keep your team organized and informed about upcoming events, practices, and meetings.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">3</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Task and Project Management</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Utilize project management tools to plan, track, and delegate tasks effectively within your team.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">4</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Internal Communication Platforms</label>
                                                <label htmlFor="" className="rating">4 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Have every each of your commands in a seperate file for a better organisation.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">5</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Slash Commands</label>
                                                <label htmlFor="" className="rating">3 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Set up communication platforms to facilitate real-time conversations, updates, and announcements among team members.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">6</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Team Roster Management</label>
                                                <label htmlFor="" className="rating">3 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Implement a system for managing your team's roster, including player profiles, roles, and responsibilities, ensuring that information is accurate and up-to-date.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">7</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Performance Analytics and Metrics</label>
                                                <label htmlFor="" className="rating">5 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Utilize data-driven tools to analyze and track team and individual performance, providing insights to inform coaching and improvement strategies.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-rect blue-gradient">
                                <img className='ilustration-img' id='ill2' src={discordDev} style={{width: "100%", margin: "0 0 20px 0"}} alt=""/>
                                <div className="sub-rect-text-content">
                                    <label htmlFor="" className="field-title" style={{lineHeight: "25px", marginBottom: "5px"}}>Team Management</label>
                                    <label htmlFor="" className="field-infos">8 Skills - <span className="material-symbols-outlined">hourglass_bottom</span> Coaches</label>
                                    
                                    <q-btn></q-btn>
                                </div>
                            </div>
                        </div>
                        <div className="rect">
                            <div className="rect-txts">
                                <label htmlFor="" className="feat-skills" style={{height: "90px"}}>Featured Solutions</label>
                                <div className="vertical-scroller">
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">1</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Recruitment Website and Portals</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Create a dedicated recruitment website or portal to attract and process applications from potential players, showcasing your team's culture, achievements, and opportunities.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">2</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Applicant Tracking Systems (ATS)</label>
                                                <label htmlFor="" className="rating">10 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Implement an Applicant Tracking System to efficiently manage the recruitment process, from application review to candidate communication and evaluation.</label>
                                        </div>
                                    </div>

                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">3</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Online Skill Assessment Platforms</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Utilize online skill assessment tools to evaluate applicants' abilities and potential fit within your team, ensuring an objective and data-driven selection process.</label>
                                        </div>
                                    </div>

                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">4</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Social Media Recruitment Campaigns</label>
                                                <label htmlFor="" className="rating">10 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Launch targeted social media campaigns to attract and engage potential talent, showcasing your team's brand and culture to a wider audience.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">5</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Player Database and Scouting</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Develop and maintain a database of potential players, using scouting tools to identify and track promising talent in the competitive scene.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">6</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Interview and Selection Tools</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Leverage technology and best practices to streamline the interview process, ensuring an efficient and effective evaluation of candidates.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">7</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Onboarding and Orientation Programs</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Design and implement engaging onboarding programs to integrate new members into your team, fostering a sense of belonging and setting them up for success.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">8</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Talent Retention Strategies</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Develop strategies to retain top talent, focusing on player satisfaction, growth opportunities, and a positive team environment.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">9</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Talent Retention Strategies</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Manage contract negotiations, renewals, and terminations with a streamlined system, ensuring all parties are informed and protected.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">10</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Contract Management and Negotiations</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Maintain connections with former team members, fostering a sense of community and leveraging their expertise and connections for future opportunities.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-rect purple-gradient">
                                <img className='ilustration-img' id="ill1" src={mediaIllustration} alt=""/>
                                <div className="sub-rect-text-content">
                                    <label htmlFor="" className="field-title">Alumni Network and Engagement</label>
                                    <label htmlFor="" className="field-infos">15 Skills - <span className="material-symbols-outlined">hourglass_bottom</span> Fields</label>
                                    
                                    <q-btn></q-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                

                <div className='ourTeam' ref={team_recruitment}>
                    
                <div className='waveWrapper'>
                    <div className="team-wave1">
                        <svg width="1440" height="699" viewBox="0 0 1440 699" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs><clipPath id="wave1"><path d="M1192 63.1469C979.197 -18.7459 969.021 -3.8326 747.038 15.147C525.055 34.1266 456.426 81.6931 291.038 63.147C125.65 44.6009 38.0215 68.2247 -41.1011 88.0053C-41.1011 175.938 -41.1013 376.556 -41.1013 376.556L1481 376.556L1481 63.1469C1481 63.1469 1405.19 145.19 1192 63.1469Z" fill="url(#paint0_linear)"></path></clipPath></defs>
                        </svg>
                    </div>

                    <div className="team-wave2">
                        <svg width="1440" height="699" viewBox="0 0 1440 699" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs><clipPath id="wave2"><path d="M1221 63.1469C1008.2 -18.7459 998.021 -3.8326 776.038 15.147C554.055 34.1266 485.426 81.6931 320.038 63.147C154.65 44.6009 67.0215 68.2247 -12.1011 88.0053C-12.1011 175.938 -12.1013 376.556 -12.1013 376.556L1510 376.556L1510 63.1469C1510 63.1469 1434.19 145.19 1221 63.1469Z"></path></clipPath></defs>
                        </svg>
                    </div>

                    <div className="team-wave3">
                        <svg width="1440" height="699" viewBox="0 0 1440 699" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs><clipPath id="wave3"><path d="M1175.8 62.9028C962.86 -18.9901 952.676 -4.07674 730.548 14.9028C508.419 33.8824 439.745 81.449 274.248 62.9029C108.752 44.3568 21.0657 67.9805 -58.1089 87.7612C-58.1089 175.694 -58.1091 584 -58.1091 584L1464.99 584L1464.99 62.9027C1464.99 62.9027 1389.13 144.946 1175.8 62.9028Z"></path></clipPath></defs>
                        </svg>
                    </div>

                    <StripWave />
                </div>

                    <div className='ourTeam-content'>
                        <div className="top section">
                            <div className="texts">
                                <label id='subtitle' htmlFor="">You want a taste of fame?</label>
                                <label htmlFor="" id="title">Help us construct<br/> a new vision.</label>
                                <p style={{marginBottom: "30px"}}>Working with us means that, if you've got an idea worth exploring, we're not about to let a manager, community, or random platinum take it away from you.</p>
                            </div>

                            <div className="tiles-container">
                                    
                                <div className="tile-team">
                                    <div className="tile-team-item">
                                        <div className="tile-team-item-content-top">
                                            <div className="tile-team-item-content-top-title">
                                                <label htmlFor="" className="tile-team-item-content-top-title-text">Creative</label>
                                            </div>
                                            <div className="tile-team-item-content-top-icon-container">
                                                <svg width={0} height={0}>
                                                    <linearGradient id="linearColors" gradientTransform="rotate(65)" x1={1} y1={0} x2={1} y2={1}>
                                                        <stop offset={0} stopColor="#eb3455" />
                                                        <stop offset={1} stopColor="#5e34eb" />
                                                    </linearGradient>
                                                </svg>
                                                <DesignServicesIcon sx={{ fill: "url(#linearColors)" }} />

                                            </div>
                                        </div>
                                        <div className="tile-team-item-content-desc">
                                            <label htmlFor="" className="tile-team-item-content-desc-text">Create outstanding designs and define standards and improve your own skillset.</label>
                                        </div>
                                        <div className='button-container'>
                                            <Link to="/standards?target=roles" className='tile-team-item-button'>Standards</Link>
                                            <a href="https://discord.gg/Jx8EEaUcFm" className='tile-team-item-button marg'>Apply</a>
                                        </div>
                                    </div>
                                    
                                    <div className="tile-team-item">
                                        <div className="tile-team-item-content-top">
                                            <div className="tile-team-item-content-top-title">
                                                <label htmlFor="" className="tile-team-item-content-top-title-text">Player</label>
                                            </div>
                                            <div className="tile-team-item-content-top-icon-container">
                                                <svg width={0} height={0}>
                                                    <linearGradient id="linearColors" gradientTransform="rotate(65)" x1={1} y1={0} x2={1} y2={1}>
                                                        <stop offset={0} stopColor="#eb3455" />
                                                        <stop offset={1} stopColor="#5e34eb" />
                                                    </linearGradient>
                                                </svg>
                                                <SportsSoccerIcon sx={{ fill: "url(#linearColors)" }} />

                                            </div>
                                        </div>
                                        <div className="tile-team-item-content-desc">
                                            <label htmlFor="" className="tile-team-item-content-desc-text">Hit creative and extraordinary clips and appear in our montages, designs and OCE's.</label>
                                        </div>
                                        <div className='button-container'>
                                            <Link to="/standards?target=roles" className='tile-team-item-button'>Standards</Link>
                                            <a href="https://discord.gg/Jx8EEaUcFm" className='tile-team-item-button marg'>Apply</a>
                                        </div>
                                    </div>    
                                    
                                </div>
                                <div className="tile-team">
                                    <div className="tile-team-item">
                                        <div className="tile-team-item-content-top">
                                            <div className="tile-team-item-content-top-title">
                                                <label htmlFor="" className="tile-team-item-content-top-title-text">Developer</label>
                                            </div>
                                            <div className="tile-team-item-content-top-icon-container">
                                                <svg width={0} height={0}>
                                                    <linearGradient id="linearColors" gradientTransform="rotate(65)" x1={1} y1={0} x2={1} y2={1}>
                                                        <stop offset={0} stopColor="#eb3455" />
                                                        <stop offset={1} stopColor="#5e34eb" />
                                                    </linearGradient>
                                                </svg>
                                                <ApiIcon sx={{ fill: "url(#linearColors)" }} />

                                            </div>
                                        </div>
                                        <div className="tile-team-item-content-desc">
                                            <label htmlFor="" className="tile-team-item-content-desc-text">Work with Discord.JS API, React, Styled Components & MUI to develop our web app and discord bot.</label>
                                        </div>
                                        <div className='button-container'>
                                            <Link to="/standards?target=roles" className='tile-team-item-button'>Standards</Link>
                                            <a href="https://discord.gg/Jx8EEaUcFm" className='tile-team-item-button marg'>Apply</a>
                                        </div>
                                    </div>
                                    
                                    <div className="tile-team-item">
                                        <div className="tile-team-item-content-top">
                                            <div className="tile-team-item-content-top-title">
                                                <label htmlFor="" className="tile-team-item-content-top-title-text">Communication</label>
                                            </div>
                                            <div className="tile-team-item-content-top-icon-container">
                                                <svg width={0} height={0}>
                                                    <linearGradient id="linearColors" gradientTransform="rotate(65)" x1={1} y1={0} x2={1} y2={1}>
                                                        <stop offset={0} stopColor="#eb3455" />
                                                        <stop offset={1} stopColor="#5e34eb" />
                                                    </linearGradient>
                                                </svg>
                                                <HandshakeIcon sx={{ fill: "url(#linearColors)" }} />

                                            </div>
                                        </div>
                                        <div className="tile-team-item-content-desc">
                                            <label htmlFor="" className="tile-team-item-content-desc-text">Communicate our vision and standards by working closely with our teams. Help us manage our public identity.</label>
                                        </div>
                                        <div className='button-container'>
                                            <Link to="/standards?target=roles" className='tile-team-item-button'>Standards</Link>
                                            <a href="https://discord.gg/Jx8EEaUcFm" className='tile-team-item-button marg'>Apply</a>
                                        </div>
                                    </div>    
                                    
                                </div>
                            </div>
                        </div>
                        {/*}
                        <div className="sub-section section">
                            <div className="container">
                                <div className="ressources">
                                    <div className='circle1'>
                                        
                                        <label>15+ GFX Packs</label>
                                    </div>
                                    <div className='circle2'>
                                        <label>15+ GFX Packs</label>
                                    </div>
                                    <div className='circle3'>
                                        <label>15+ GFX Packs</label>
                                    </div>
                                </div>
                                
                                <div className="texts">
                                    <label id='subtitle' htmlFor="">150+ Development ressources</label>
                                    <label htmlFor="" id="title">Help us construct<br/> a new vision.</label>
                                    <p style={{marginBottom: "30px"}}>Above it all, Sypher is a Rocket League clip-hitting team. Joining us means bringing your own knowledge and ideas to the development of both our team and platform.</p>
                                </div>
                            </div>
                        </div>
                    */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing;